var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('c-flex',{attrs:{"as":"nav","id":"bottomNavigation","pos":"fixed","left":"0","bottom":"0px","h":"60px","w":"100vw","bg":"white","align-items":"center","justify-content":"space-around","box-shadow":"4px -4px 50px 5px rgba(0, 0, 0, 0.15)","display":['flex', 'none'],"z-index":"9"}},[(_vm.isMenuActive)?_c('portal',{attrs:{"to":"main"}},[_c('modal-menu',{attrs:{"zIndex":"1000"}})],1):_vm._e(),_vm._l((_vm.menus),function(menu){return [(menu.route == null)?_c('c-flex',{key:menu.name},[_c('c-button',{attrs:{"variant":"ghost","p":"0","m":"0","flex-direction":"column","justify-content":"center","align-items":"center"},on:{"click":function($event){return _vm.onMenuClicked(menu)}}},[_c('c-image',{staticClass:"icon",attrs:{"alt":menu.name,"src":menu.icon,"w":"24px","h":"24px"}}),_c('c-text',{directives:[{name:"chakra",rawName:"v-chakra",value:({
            '&.active': {
              color: 'brand.900',
            },
          }),expression:"{\n            '&.active': {\n              color: 'brand.900',\n            },\n          }"}],class:{ active: menu.isActive },attrs:{"font-size":"10px","font-weight":"500","color":"#c4c4c4"}},[_vm._v(" "+_vm._s(menu.name)+" ")])],1)],1):_vm._e(),(menu.route != null)?_c('c-link',{key:menu.name,staticClass:"menu-item",attrs:{"as":"router-link","to":menu.route,"exact-active-class":"active"},on:{"click":function($event){return _vm.onMenuClicked(menu)}}},[_c('c-flex',{attrs:{"flex-direction":"column","justify-content":"center","align-items":"center"}},[_c('c-image',{staticClass:"icon",attrs:{"alt":menu.name,"src":menu.icon,"w":"24px","h":"24px"}}),_c('c-text',{directives:[{name:"chakra",rawName:"v-chakra",value:({
            '&.active': {
              color: 'brand.900',
            },
          }),expression:"{\n            '&.active': {\n              color: 'brand.900',\n            },\n          }"}],class:{ active: menu.isActive },attrs:{"font-size":"10px","font-weight":"500","color":"#c4c4c4"}},[_vm._v(" "+_vm._s(menu.name)+" ")])],1)],1):_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }