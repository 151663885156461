<template>
  <c-flex
    as="nav"
    id="bottomNavigation"
    pos="fixed"
    left="0"
    bottom="0px"
    h="60px"
    w="100vw"
    bg="white"
    align-items="center"
    justify-content="space-around"
    box-shadow="4px -4px 50px 5px rgba(0, 0, 0, 0.15)"
    :display="['flex', 'none']"
    z-index="9"
  >
    <portal v-if="isMenuActive" to="main">
      <modal-menu zIndex="1000" />
    </portal>
    <template v-for="menu in menus">
      <c-flex v-if="menu.route == null" :key="menu.name">
        <c-button
          variant="ghost"
          p="0"
          m="0"
          flex-direction="column"
          justify-content="center"
          align-items="center"
          @click="onMenuClicked(menu)"
        >
          <c-image
            class="icon"
            :alt="menu.name"
            :src="menu.icon"
            w="24px"
            h="24px"
          />
          <c-text
            :class="{ active: menu.isActive }"
            font-size="10px"
            font-weight="500"
            color="#c4c4c4"
            v-chakra="{
              '&.active': {
                color: 'brand.900',
              },
            }"
          >
            {{ menu.name }}
          </c-text>
        </c-button>
      </c-flex>
      <c-link
        v-if="menu.route != null"
        as="router-link"
        :to="menu.route"
        class="menu-item"
        exact-active-class="active"
        :key="menu.name"
        @click="onMenuClicked(menu)"
      >
        <c-flex
          flex-direction="column"
          justify-content="center"
          align-items="center"
        >
          <c-image
            class="icon"
            :alt="menu.name"
            :src="menu.icon"
            w="24px"
            h="24px"
          />
          <c-text
            :class="{ active: menu.isActive }"
            font-size="10px"
            font-weight="500"
            color="#c4c4c4"
            v-chakra="{
              '&.active': {
                color: 'brand.900',
              },
            }"
          >
            {{ menu.name }}
          </c-text>
        </c-flex>
      </c-link>
    </template>
  </c-flex>
</template>

<script>
import { mapState } from "vuex";
import ModalMenu from "@/components/modals/modal-menu.vue";

export default {
  name: "nav-bottom",
  components: {
    ModalMenu,
  },
  data() {
    return {
      isMenuActive: false,
    };
  },
  computed: {
    ...mapState({
      user: (s) => s.auth.user,
      role: (s) => s.auth.user?.role,
    }),
    menus() {
      const self = this;
      if (self.role === "client") {
        return [
          {
            name: "Beranda",
            route: "/",
            get icon() {
              if (this.isActive) {
                return require("@/assets/icon-home-active.svg");
              } else {
                return require("@/assets/icon-home-grey.svg");
              }
            },
            get isActive() {
              return !self.isMenuActive && self.$route.path === "/";
            },
          },
          {
            name: "Menu",
            get icon() {
              if (this.isActive) {
                return require("@/assets/icon-menu-dots-active.svg");
              }
              return require("@/assets/icon-menu-dots-grey.svg");
            },
            get isActive() {
              return self.isMenuActive;
            },
          },
          {
            name: "Keranjang",
            route: "/cart",
            get icon() {
              if (this.isActive)
                return require("@/assets/icon-cart-active.svg");
              return require("@/assets/icon-cart-grey.svg");
            },
            get isActive() {
              return !self.isMenuActive && self.$route.name === "client.cart";
            },
          },
          {
            name: "Profile",
            route: "/profile",
            get icon() {
              if (this.isActive)
                return require("@/assets/icon-user-active.svg");
              return require("@/assets/icon-user-grey.svg");
            },
            get isActive() {
              return (
                !self.isMenuActive && self.$route.name === "client.profile"
              );
            },
          },
        ];
      }

      return [
        {
          name: "Beranda",
          route: "/management-client",
          get icon() {
            if (this.isActive) {
              return require("@/assets/icon-home-active.svg");
            } else {
              return require("@/assets/icon-home-grey.svg");
            }
          },
          get isActive() {
            return (
              !self.isMenuActive && self.$route.path === "/management-client"
            );
          },
        },
        {
          name: "Coaching Course",
          route: "/content-challenge",
          get icon() {
            if (this.isActive) {
              return require("@/assets/icon-target-active.svg");
            }
            return require("@/assets/icon-target.svg");
          },
          get isActive() {
            return (
              !self.isMenuActive && self.$route.path === "/content-challenge"
            );
          },
        },
        {
          name: "Profile",
          route: "/profile",
          get icon() {
            if (this.isActive) return require("@/assets/icon-user-active.svg");
            return require("@/assets/icon-user-grey.svg");
          },
          get isActive() {
            return !self.isMenuActive && self.$route.name === "client.profile";
          },
        },
      ];
    },
  },
  methods: {
    onMenuClicked(menu) {
      if (menu.name === "Menu") {
        this.isMenuActive = !this.isMenuActive;
      }
    },
  },
};
</script>
