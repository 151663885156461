<template>
  <c-flex
    as="nav"
    id="profileNavigation"
    :position="['fixed', 'static']"
    :bottom="[0]"
    left="0"
    :h="['60px', 'auto']"
    :w="['100vw', 'auto']"
    background="white"
    align-items="center"
    justify-content="space-around"
    :box-shadow="['4px -4px 50px 5px rgba(0, 0, 0, 0.15)', 'none']"
    :d="['flex', 'block']"
    :z-index="[999, 1]"
    :mt="[null, '60px']"
    border-right="1px solid #f2f2f2"
  >
    <c-link
      as="router-link"
      v-for="menu in menus"
      :key="menu.name"
      :to="menu.route"
      class="menu-item"
      exact-active-class="active"
    >
      <c-flex
        :flex-direction="['column', 'row']"
        :justify-content="['center', 'flex-start']"
        align-items="center"
        :h="[null, '66px']"
        :w="[null, '327px']"
        :mr="[null, '20px']"
        :border-radius="[null, '12px']"
        v-chakra="{
          '.active &': {
            bg: [null, '#f5f5f5'],
          },
        }"
      >
        <c-box
          w="7px"
          h="100%"
          border-top-left-radius="15px"
          border-bottom-left-radius="15px"
          v-chakra="{
            '.active &': {
              bg: [null, 'brand.900'],
            },
          }"
        />
        <c-image
          class="icon"
          :alt="menu.name"
          :src="menu.icon"
          w="24px"
          h="24px"
          :ml="[null, '25px']"
        />
        <c-text
          :class="{ active: menu.isActive }"
          :font-size="['10px', '16px']"
          :font-weight="['500']"
          :ml="[null, '20px']"
          color="#c4c4c4"
          v-chakra="{
            '&.active': {
              color: 'brand.900',
            },
          }"
        >
          {{ menu.name }}
        </c-text>
      </c-flex>
    </c-link>
  </c-flex>
</template>

<script>
export default {
  name: "nav-bottom",
  computed: {
    menus() {
      const self = this;
      return [
        {
          name: "Data Diri",
          route: { name: "client.profile.detail" },
          get icon() {
            if (this.isActive) {
              return require("@/assets/icon-data-diri-active.svg");
            } else {
              return require("@/assets/icon-data-diri.svg");
            }
          },
          get isActive() {
            return self.$route.name === "client.profile.detail";
          },
        },
        {
          name: "Data Diet",
          route: { name: "client.profile.data-diet" },
          get icon() {
            if (this.isActive) {
              return require("@/assets/icon-data-diet-active.svg");
            }
            return require("@/assets/icon-data-diet.svg");
          },
          get isActive() {
            return self.$route.name === "client.profile.data-diet";
          },
        },
        {
          name: "Riwayat Program",
          route: { name: "client.profile.program" },
          get icon() {
            if (this.isActive)
              return require("@/assets/icon-profile-program-active.svg");
            return require("@/assets/icon-profile-program.svg");
          },
          get isActive() {
            return self.$route.name === "client.profile.program";
          },
        },
        {
          name: "Riwayat Pembelian",
          route: { name: "client.profile.pembelian" },
          get icon() {
            if (this.isActive) return require("@/assets/icon-cart-active.svg");
            return require("@/assets/icon-cart-grey.svg");
          },
          get isActive() {
            return self.$route.name === "client.profile.pembelian";
          },
        },
        {
          name: "Pengaturan",
          route: { name: "client.profile.settings" },
          get icon() {
            if (this.isActive)
              return require("@/assets/icon-settings-active.svg");
            return require("@/assets/icon-settings.svg");
          },
          get isActive() {
            return self.$route.name === "client.profile.settings";
          },
        },
      ];
    },
  },
};
</script>

<style>
body {
  padding-bottom: 75px;
}
</style>
